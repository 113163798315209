import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectProfile, setProfile } from "@features/user/userSlice";
import userApi from "@features/user/userApi";
import i18n from "@app/i18n";
import { logError } from "@features/share/helper/system";
import {
  fetchBookingProfile,
  fetchCreatorProfile,
  fetchPassioPageProfile,
  getPackageOfUser,
} from "@share/api";
import { setPassioPageProfile } from "@features/main/store/passioPageProfileSlice";
import { fetchAllowStore } from "@features/mini-store/share/api";
import isEmpty from "lodash/isEmpty";
import { setCreatorProfile } from "@features/main/store/creatorProfileSlice";
import { setBookingProfile } from "@features/main/store/bookingProfileSlice";
import { useTranslation } from "react-i18next";
import { useModal } from "@share/hooks/modal";

export default function Logged({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();
  const kol = useSelector(selectProfile);
  const fetchAccount = async () => {
    try {
      const response = await userApi.me();
      const getPackageOfUse = await getPackageOfUser();
      const profile = {
        ...response,
        package_type: getPackageOfUse?.data?.data?.language?.id,
        package_name: getPackageOfUse?.data?.data?.language?.name,
        package_description: getPackageOfUse?.data?.data?.language?.description,
      };
      // dispatch(setProfile(response));
      dispatch(setProfile(profile));
      const passioPageProfile = await fetchPassioPageProfile(response._id);
      dispatch(setPassioPageProfile({ data: passioPageProfile }));

      const creatorProfile = await fetchCreatorProfile();
      const bookingProfile = await fetchBookingProfile();
      dispatch(setBookingProfile({ data: bookingProfile }));

      dispatch(setCreatorProfile({ data: creatorProfile }));

      const allowStore = await fetchAllowStore(response._id);
      if (!isEmpty(allowStore)) {
        window["AllowStore"] = allowStore;
      }
    } catch (error) {
      logError(error, "Locale - fetchAccount");
    }
  };

  const getLocale = async () => {
    if (!kol) {
      fetchAccount().then();
      return;
    }

    if (kol.locale) {
      i18n.changeLanguage(kol.locale).then();
    }
  };

  useEffect(() => {
    getLocale().then();
  }, [kol]);

  if (!kol) return <> </>;

  return <>{children}</>;
}
