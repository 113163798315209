import { useAppSelector } from "@app/hooks";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import { THEME_RENDER_LIST_KEY } from "@share/configs/const";
import {
  CheckCircleIconV2,
  CommentInformationIcon,
  DeleteIcon,
  InformationTriangleIcon,
  MoreIcon,
  EyeVisibleIcon,
  EyeHiddenIcon,
  CrownIcon,
} from "@share/icons";
import { Image, Modal, Popover, Toast } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getHorizontalResizeUrl } from "@features/share/helper/image";
import cls from "classnames";

export interface themeTemplateInterface {
  id: string;
  user_id?: string;
  title: string;
  image: string;
  status?: any;
  tags?: Array<string>;
  is_premium?: boolean;
}

type Props = {
  themeTemplateData?: themeTemplateInterface[];
  onSelectTheme: any;
  onEditTheme?: any;
  onDeleteTheme?: any;
  KEY?: string;
  onShowHideTheme?: any;
};

const ThemeTemplateLists = (props: Props) => {
  const {
    themeTemplateData,
    onSelectTheme,
    onEditTheme,
    onDeleteTheme,
    KEY,
    onShowHideTheme,
  } = props;
  const popoverRef = useRef(null);
  const { t } = useTranslation();
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const creator_role = passioPageProfile?.creator_role;

  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );

  const getAction = (item) => {
    return [
      {
        key: "edit-theme-template",
        icon: (
          <CommentInformationIcon
            className="w-5 h-5"
            fillColor="white"
          />
        ),
        text: t("ss_builder_edit_theme_template_label"),
      },
      {
        key: item?.status === 1 ? "hide-theme-template" : "show-theme-template",
        icon:
          item?.status !== 1 ? (
            <EyeVisibleIcon className="w-5 h-5" />
          ) : (
            <EyeHiddenIcon className="w-5 h-5" />
          ),
        text:
          item?.status === 1
            ? t("ss_builder_hide_theme_template_label")
            : t("ss_builder_show_theme_template_label"),
      },
      {
        key: "delete-theme-template",
        icon: <DeleteIcon className="w-5 h-5" />,
        text: t("ss_builder_delete_theme_template_label"),
      },
    ];
  };
  const [selectedThemeData, setSelectedThemeData] = useState(null);
  const handleSelectThemeTemplate = (themeData) => {
    if (KEY === THEME_RENDER_LIST_KEY.THEME_OVERWRITE_SELECTION) {
      if (themeData.id === selectedThemeData?.id) {
        setSelectedThemeData(null);
      } else {
        setSelectedThemeData(themeData);
      }
    } else {
      onSelectTheme(themeData);
    }
  };

  useEffect(() => {
    onSelectTheme(selectedThemeData);
  }, [selectedThemeData]);

  const handleClickMoreIcon = (event) => {
    event.preventDefault();
    // event.stopPropagation();
  };

  const handleClickPopoverItem = (item: Action, themeId: string) => {
    const key = item && item.key;
    const themeData = themeTemplateData?.find((x) => x.id === themeId);
    switch (key) {
      case "edit-theme-template":
        handleEditThemeTemplate(themeData);
        break;
      case "delete-theme-template":
        handleDeleteThemeTemplate(themeId);
        break;
      case "hide-theme-template":
        handleShowHideThemeTemplate(themeId);
        break;
      case "show-theme-template":
        handleShowHideThemeTemplate(themeId);
        break;
      default:
      // to do
    }
  };

  const handleEditThemeTemplate = (themeData) => {
    onEditTheme(themeData);
  };

  const handleShowHideThemeTemplate = (themeId) => {
    onShowHideTheme(themeId);
  };

  const handleDeleteThemeTemplate = (themeId) => {
    Modal.confirm({
      header: (
        <InformationTriangleIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: (
        <div>{t("ss_builder_confirm_delete_theme_template_modal_title")}</div>
      ),
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm_delete_theme_template_modal_content")}
        </div>
      ),
      confirmText: t(
        "ss_builder_confirm_delete_theme_template_modal_btn_label"
      ),
      cancelText: t("ss_builder_cancel_delete_theme_template_modal_btn_label"),
      onConfirm: () => {
        onDeleteTheme(themeId);
      },
      onCancel: () => {
        return;
      },
    });
  };

  return (
    <div
      className="px-4 pt-4 pb-[65px] bg-white"
      ref={popoverRef}
    >
      <div className="grid grid-cols-3 gap-3 theme-store-tour-step-2 bg-[#fff] mb-3">
        {themeTemplateData?.slice(0, 3)?.map((ele, index) => {
          const listActions = getAction(ele);

          return (
            <div key={ele.id}>
              <div
                className={cls(
                  "relative cursor-pointer",
                  ele.status !== 1 && "opacity-40"
                )}
              >
                <div
                  // src={getHorizontalResizeUrl(ele.image)}
                  // fit="cover"
                  style={{
                    background: `url("${getHorizontalResizeUrl(ele.image)}")`,
                    aspectRatio: "9/16",
                    borderRadius: "4px",
                    border:
                      ele.id === selectedThemeData?.id
                        ? "2px solid #EE3244"
                        : "2px solid #00000029",
                    backgroundPosition: "center top",
                    backgroundSize: "cover",
                  }}
                  onClick={() => handleSelectThemeTemplate(ele)}
                ></div>
                {/* <Image
                src={getHorizontalResizeUrl(ele.image)}
                fit="cover"
                style={{
                  aspectRatio: "9/16",
                  borderRadius: "4px",
                  border:
                    ele.id === selectedThemeData?.id
                      ? "2px solid #EE3244"
                      : "2px solid #00000029",
                }}
                onClick={() => handleSelectThemeTemplate(ele)}
              /> */}
                {ele.id === selectedThemeData?.id && (
                  <div className="absolute top-2 right-2 rounded-full bg-[#EE3244]">
                    <CheckCircleIconV2
                      className="w-5 h-5 "
                      fillColor="white"
                    />
                  </div>
                )}
                {KEY === THEME_RENDER_LIST_KEY.THEME_STORE && isAdminRole && (
                  <div
                    className="absolute top-2 right-2 rounded-[4px] bg-[#00000099] p-[2px] z-[6]"
                    onClick={handleClickMoreIcon}
                  >
                    <Popover.Menu
                      mode="dark"
                      actions={listActions}
                      placement="bottom"
                      trigger="click"
                      stopPropagation={["click"]}
                      getContainer={() => popoverRef.current}
                      onAction={(item: Action) =>
                        handleClickPopoverItem(item, ele.id)
                      }
                    >
                      <MoreIcon
                        className="w-4 h-4 "
                        fillColor="white"
                      />
                    </Popover.Menu>
                  </div>
                )}
                {ele?.is_premium && (
                  <div className="absolute top-[6px] right-[6px] rounded-[4px] p-[2px] z-[5]">
                    <CrownIcon />
                  </div>
                )}
              </div>
              <div className="text-xs leading-4 text-[#8C8C8C] mt-2 text-ellipsis overflow-hidden whitespace-nowrap">
                {ele.title}
              </div>
            </div>
          );
        })}
      </div>
      {themeTemplateData.length > 3 && (
        <div className="grid grid-cols-3 gap-3">
          {themeTemplateData?.slice(3)?.map((ele, index) => {
            const listActions = getAction(ele);
            return (
              <div key={ele.id}>
                <div
                  className={cls(
                    "relative cursor-pointer",
                    ele.status !== 1 && "opacity-40"
                  )}
                >
                  <div
                    // src={getHorizontalResizeUrl(ele.image)}
                    // fit="cover"
                    style={{
                      background: `url("${getHorizontalResizeUrl(ele.image)}")`,
                      aspectRatio: "9/16",
                      borderRadius: "4px",
                      border:
                        ele.id === selectedThemeData?.id
                          ? "2px solid #EE3244"
                          : "2px solid #00000029",
                      backgroundPosition: "center top",
                      backgroundSize: "cover",
                    }}
                    onClick={() => handleSelectThemeTemplate(ele)}
                  ></div>
                  {/* <Image
                src={getHorizontalResizeUrl(ele.image)}
                fit="cover"
                style={{
                  aspectRatio: "9/16",
                  borderRadius: "4px",
                  border:
                    ele.id === selectedThemeData?.id
                      ? "2px solid #EE3244"
                      : "2px solid #00000029",
                }}
                onClick={() => handleSelectThemeTemplate(ele)}
              /> */}
                  {ele.id === selectedThemeData?.id && (
                    <div className="absolute top-2 right-2 rounded-full bg-[#EE3244]">
                      <CheckCircleIconV2
                        className="w-5 h-5 "
                        fillColor="white"
                      />
                    </div>
                  )}
                  {KEY === THEME_RENDER_LIST_KEY.THEME_STORE && isAdminRole && (
                    <div
                      className="absolute top-2 right-2 rounded-[4px] bg-[#00000099] p-[2px] z-[6]"
                      onClick={handleClickMoreIcon}
                    >
                      <Popover.Menu
                        mode="dark"
                        actions={listActions}
                        placement="bottom"
                        trigger="click"
                        stopPropagation={["click"]}
                        getContainer={() => popoverRef.current}
                        onAction={(item: Action) =>
                          handleClickPopoverItem(item, ele.id)
                        }
                      >
                        <MoreIcon
                          className="w-4 h-4 "
                          fillColor="white"
                        />
                      </Popover.Menu>
                    </div>
                  )}
                  {ele?.is_premium && (
                    <div className="absolute top-[6px] right-[6px] rounded-[4px] p-[2px] z-[5]">
                      <CrownIcon />
                    </div>
                  )}
                </div>
                <div className="text-xs leading-4 text-[#8C8C8C] mt-2 text-ellipsis overflow-hidden whitespace-nowrap">
                  {ele.title}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ThemeTemplateLists;
